<template>
	<div v-if="isLoggedIn">
		<Template-base-empty class="containerCard">
			<div class="container-row-main" :style="'width: calc(100% + 30px) !important;'">
				<div class="container-head">
					<span class="container-head-title">Meu Perfil</span>
					<div class="container-salvar">
						<b-button
							class="salvar-button"
							@click="salvar({})"
							:disabled="invalidForm"
						>
							Salvar
						</b-button>
					</div>
				</div>
				<div class="container-user">
					<div class="head-row row-dark">
						<b-col cols="5" class="text-head-row">
							<label>
								Usuário
							</label>
						</b-col>
						<b-col cols="7" class="text-head-row">
							<b-form-input
								name="usuario"
								type="text"
								autocomplete="off"
								class="input-text"
								:disabled="loading"
								v-model="usuario.usuario"
								v-validate="{ required: true }"
								:state="validateState('usuario')"
							/>
						</b-col>
					</div>
				</div>
				<div class="container-user">
					<div class="head-row">
						<b-col cols="5" class="text-head-row">
							<label>
								Nome
							</label>
						</b-col>
						<b-col cols="7" class="text-head-row">
							<b-form-input
								name="nome"
								type="text"
								autocomplete="off"
								class="input-text"
								:disabled="loading"
								v-model="usuario.nome"
								v-validate="{ required: true }"
								:state="validateState('nome')"
							/>
						</b-col>
					</div>
				</div>
				<div class="container-user">
					<div class="head-row row-dark">
						<b-col cols="5" class="text-head-row">
							<label>
								E-mail
							</label>
						</b-col>
						<b-col cols="7" class="text-head-row">
							<b-form-input
								name="email"
								type="email"
								autocomplete="off"
								class="input-text"
								:disabled="loading"
								v-model="usuario.email"
								v-validate="{ required: false, email: true }"
								:state="validateState('email')"
							/>
						</b-col>
					</div>
				</div>
				<div class="container-user mt-2">
					<div class="head-row mb-2">
						<b-col cols="5" class="text-head-row">
							<label>
								Senha
							</label>
						</b-col>
						<b-col cols="7" class="text-head-row">
							<InputSenha
								class="input-text"
								id="senha"
								name="senha"
								v-model="usuario.senha"
								v-validate="{ required: false }"
								:state="validateState('senha')"
								placeholder="Senha inalterada"
							/>
						</b-col>
					</div>
				</div>
			</div>
		</Template-base-empty>
	</div>
</template>

<script>
	import { createHash } from "crypto";

	import InputSenha from "@/components/InputSenha";
	import TemplateBaseEmpty from "@/templates/BaseEmpty";

	import { AuthService } from "../../services/auth";
	import { UsersService } from "../../services/users";

	export default {
		components: {
			TemplateBaseEmpty,
			InputSenha
		},

		inject: ["parentValidator"],

		data () {
			return {
				usuario: {
					usuario: null,
					nome: null,
					email: null,
					senha: null
				},

				loading: false,
				invalidForm: true,
				usersService: new UsersService(),
				errMsg: "",
				authService: new AuthService(),
				isLoggedIn: false
			};
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.isLoggedIn = await this.authService.isLoggedIn();
			if (!this.isLoggedIn)
				this.$router.push({ path: "/" });
			else
				this.findUser();
		},

		methods: {
			async findUser () {
				const { usuario, nomeUsuarioCompleto, email } = JSON.parse(localStorage.params);

				this.invalidForm = true;
				this.usuario.usuario = usuario;
				this.usuario.nome = nomeUsuarioCompleto;
				this.usuario.email = email;
				this.usuario.senha = null;
			},

			async salvar () {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				await this.$snotify.async("Aguarde...", "Salvando!", async () => {
					try {
						const payload = {
							usuario: this.usuario.usuario,
							nome: this.usuario.nome,
							email: this.usuario.email
						};

						if (this.usuario.senha)
							payload.senha = createHash("sha512").update(this.usuario.senha).digest("hex");

						const usuarioLogado = await this.usersService.updateProfile(payload);
						this.$store.commit("setUsuarioLogado", null);
						this.$store.commit("setUsuarioLogado", usuarioLogado);

						return {
							title: "Sucesso!",
							body: "Usuário salvo com sucesso.",
							config
						};
					} catch (error) {
						let body = "Erro ao tentar salvar usuário.";
						if (error.response.data.error && typeof error.response.data.error === "string")
							body = error.response.data.error;

						throw {
							title: "Falha!",
							body,
							config
						};
					}
				});
			},

			validateState (ref) {
				if (this.veeFields[ref] && this.veeFields[ref].dirty && this.veeFields[ref].validated) {
					this.invalidForm = Boolean(this.veeErrors.items.length);
					return !this.veeErrors.has(ref);
				}

				return null;
			}
		}
	};
</script>

<style scoped>
	.container-salvar {
		display: flex;
		width: 50%;
		height: 3rem;
		justify-content: right;
		margin-right: 8px;
	}

	.salvar-button {
		width: 135;
		height: 43;
		font-weight: 400;
		background-color: #218838;
		color: #FFFFFF;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		padding: .75rem 1.5rem;
		border-style: none;
		margin-bottom: .5rem;
	}

	.container-row-main {
		margin-left: -15px;
		background-color: #FFFFFF;
		border-radius: 10px;
		box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
	}

	.container-head {
		display: flex;
		height: 56px;
		background-color: #6dcff6 ;
		border-radius: 10px 10px 0 0;
		padding-top: 0.5rem;
		margin-bottom: 2rem;
	}

	.container-user {
		margin-left: 1.5rem;
	}

	.container-head-title {
		width: 50%;
		height: 3rem;
		margin-left: 16px;
		margin-top: -5px;
		color: #FFFFFF;
		font-size: 30px;
		font-weight: 800;
	}

	.head-row {
		display: flex;
		align-items: center;
		width: calc(100% - 24px);
		height: 4rem;
	}

	.row-dark {
		background-color: rgba(0, 0, 0, .05);
		border: 1px solid rgba(0, 0, 0, .1);
		border-right: none;
		border-left: none;
	}

	.text-head-row {
		align-items: center;
		font-size: 1rem;
	}

	.input-text {
		align-items: center;
		font-size: 1rem;
	}
</style>
